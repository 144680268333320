import React, { useContext, useState } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../Firebase/firebase-config";
import { doc, updateDoc } from "firebase/firestore";
import { TutorielsContext } from "../App";

import EdiText from "react-editext";

import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Input from "@mui/material/Input";

import ReactPlayer from "react-player";

import FeedBackModal from "./FeedbackModal";

const TutoAdmin = (props) => {
  const [videoURL, setVideoURL] = useState(props.data.videoURL);
  const [videoUrlInput, setVideoUrlInput] = useState(null);
  const [description, setDescription] = useState(props.data.description);
  const [feedback, setFeedback] = useState(props.data.feedback);
  const [question, setQuestion] = useState(props.data.question);
  const [responses, setResponses] = useState(props.data.responses);
  const [helperText, setHelperText] = useState(
    "Cochez la(les) bonne(s) reponse(s)"
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tutoRef = doc(db, "tutoriels", props.id);

  const handleVideoChange = (e) => {
    //modifie l URL de la video du tutoriel courant
    e.preventDefault();
    const url = videoUrlInput;
    updateDoc(tutoRef, {
      videoURL: url,
    }).then(
      (_) => {
        setVideoURL(url);
        setVideoUrlInput(null);
      },
      (err) => alert(err)
    );
  };

  const checkVideoURLFormat = (url) => {
    //verifie si l URL de la video respecte le format indiqué
    return url === null
      ? true
      : !url.includes("https://www.youtube.com/embed/");
  };

  const handleResponseStatusUpdate = (e, respId) => {
    // modifie le statut d'une reponse: si elle est bonne ou pas
    e.preventDefault();

    const updatedResponses = responses.map((respTemp, respTempId) => {
      if (respTempId === respId) {
        return { ...respTemp, correct: e.target.checked };
      } else {
        return respTemp;
      }
    });
    updateDoc(tutoRef, {
      responses: updatedResponses,
    }).then((_) => {
      setResponses(updatedResponses);
    });
  };

  const handleResponseContentChange = (newResp, respId) => {
    //gere le changement du contenu d'une reponse
    const newResponses = responses.map((respTemp, respTempId) => {
      if (respTempId === respId) {
        // mettre la nouvelle valeur
        return { ...respTemp, content: newResp };
      } else {
        //pas de changement
        return respTemp;
      }
    });
    updateDoc(tutoRef, {
      responses: newResponses,
    }).then((_) => setResponses(newResponses));
  };

  const handleDescriptionUpdate = (newDescr) => {
    //fonction qui met à jour la description
    if (newDescr !== description) {
      updateDoc(tutoRef, {
        description: newDescr,
      }).then(() => setDescription(newDescr));
    }
  };

  const handleResponseDelete = (e, respId) => {
    //fonction qui permet la suppression d une reponse
    e.preventDefault();
    //console.log(correctResponse, respId);
    const newResponses = responses.filter(
      (_, respTempId) => respTempId !== respId
    );
    updateDoc(tutoRef, { responses: newResponses }).then((_) => {
      setResponses(newResponses);
    });
  };

  const handleAddResponse = (e) => {
    //fonction qui permet l ajout d une nouvelle reponse
    e.preventDefault();
    const newResponses = [
      ...responses,
      { content: "nouvelle reponse", correct: false },
    ];
    updateDoc(tutoRef, {
      responses: newResponses,
    }).then((_) => setResponses(newResponses));
  };

  const checkCheckboxDisabled = (respId) => {
    //verifie si la checkbox doit toujours etre active ou pas (une bonne reponse au moins doit etre cochée)
    const numCorrResponses = responses.reduce((acc, resp) => {
      return resp.correct === true ? acc + 1 : acc;
    }, 0);
    return numCorrResponses === 1 && responses[respId].correct === true
      ? true
      : false;
  };

  const handleModalOpen = (e) => {
    //gere l'ouverture du modal
    e.preventDefault();
    setIsModalOpen(true);
  };
  const handleModalClose = (e) => {
    //gere la fermeture du modal
    e.preventDefault();
    setIsModalOpen(false);
  };

  const handleFeedbackChange = (newFeedback) => {
    //gere le changement du message de feedback
    if (newFeedback !== feedback) {
      updateDoc(tutoRef, {
        feedback: newFeedback,
      }).then(() => setFeedback(newFeedback));
    }
  };
  return (
    <div className="tuto-container">
      <div className="left-vid-block">
        <div className="vid-container">
          <ReactPlayer url={videoURL} controls={true} />
        </div>
        <div className="vid-butt-container">
          <FormControl sx={{ width: "50%" }} variant="standard" on>
            <Input
              placeholder="https://www.youtube.com/embed/xxxxxxxxxxxx"
              type="text"
              value={videoUrlInput ? videoUrlInput : ""}
              onChange={(event) => setVideoUrlInput(event.target.value)}
            />
            <FormHelperText>Entrez une url de video YouTube</FormHelperText>
          </FormControl>
          <button
            className="button button2 tuto-pg-input-vid-button"
            onClick={(e) => handleVideoChange(e)}
            disabled={checkVideoURLFormat(videoUrlInput)}
            color="inherit"
          >
            Changer la vidéo
          </button>
        </div>
      </div>
      <div className="tuto-pg-right-text-block">
        <div className="tuto-pg-explication-block">
          <EdiText
            viewContainerClassName="my-custom-view-wrapper"
            type="textarea"
            inputProps={{
              rows: 10,
            }}
            saveButtonContent="Appliquer"
            cancelButtonContent={<strong>Annuler</strong>}
            editButtonClassName="button button2 tuto-pg-description-edit-butt"
            editButtonContent="Modifier"
            value={description}
            onSave={(newMsg) => handleDescriptionUpdate(newMsg)}
            hideIcons={false}
          />
        </div>
        <div className="tuto-pg-questions-block">
          <div className="tuto-pg-question-container">
            <EdiText
              showButtonsOnHover
              type="text"
              value={question}
              viewProps={{
                className: "tuto-pg-question",
              }}
              onSave={(newQuestion) => {
                setQuestion(newQuestion);
              }}
            />
          </div>
          <form className="tuto-pg-responses-form">
            <FormControl
              sx={{ width: "100%", height: "100%" }}
              variant="standard"
            >
              {responses.map((resp, respId) => (
                <div key={respId} className="tuto-pg-response-wrapper">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={resp.correct}
                        disabled={checkCheckboxDisabled(respId)}
                        onChange={(e) => handleResponseStatusUpdate(e, respId)}
                      />
                    }
                    label={
                      <EdiText
                        showButtonsOnHover
                        type="text"
                        value={resp.content}
                        viewProps={{
                          className: "tuto-pg-response",
                        }}
                        onSave={(newResp) =>
                          handleResponseContentChange(newResp, respId)
                        }
                      />
                    }
                  />
                  <button
                    key={`${respId}-butt`}
                    className="button button2 tuto-pg-response-delete-butt"
                    onClick={(e) => handleResponseDelete(e, respId)}
                    disabled={checkCheckboxDisabled(respId)}
                  >
                    x
                  </button>
                </div>
              ))}
              <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
          </form>
          <div className="tuto-pg-submit-button-container">
            <button
              className="button button2 tuto-pg-submit-butt"
              onClick={handleAddResponse}
            >
              Ajouter reponse
            </button>
            <button
              className="button button2 tuto-pg-submit-butt"
              onClick={handleModalOpen}
            >
              Modifier feedback
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <FeedBackModal>
          <div className="feedback-modal-title-container-admn">
            <Typography variant="h3">Commentaires</Typography>
          </div>
          <div className="feedback-modal-msg-container-admn">
            <EdiText
              viewContainerClassName="my-custom-view-wrapper"
              type="textarea"
              inputProps={{
                rows: 10,
              }}
              saveButtonContent="Appliquer"
              cancelButtonContent={<strong>Annuler</strong>}
              editButtonClassName="button button2 tuto-pg-feedback-modal-edit-butt"
              editButtonContent="Modifier"
              value={feedback}
              onSave={(newFeedback) => handleFeedbackChange(newFeedback)}
              hideIcons={false}
            />
          </div>
          <div className="modal-butt-container feedback-modal-butt-container-admn">
            <button
              className="button button2 modal-butt"
              onClick={handleModalClose}
            >
              Terminer
            </button>
          </div>
        </FeedBackModal>
      )}
    </div>
  );
};

export default TutoAdmin;

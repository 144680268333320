import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router";
import { db } from "../Firebase/firebase-config";
import {
  doc,
  getDoc,
} from "firebase/firestore";

const FirstPage = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const pageAttributesRef = doc(db, "pages", "pageBienvenue");

  useEffect(() => {
    const getPageAttributes = () => {
      getDoc(pageAttributesRef).then((doc) => {
        setTitle(doc.data().title);
        setMessage(doc.data().message);
      });
    };
    getPageAttributes();
  }, []);

  return (
    <div className="page-container">
      <div className="logo-container">
        <div className="logo-img-container jbthiery-logo-container">
          <img
            className="logo"
            src="./img/jbthierrylogo.png"
            alt="logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="logo-img-container mines-logo-container">
          <img
            className="logo"
            src="./img/mines-nancy.png"
            alt="logo"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
      <div className="first-pg-msg-container">
        <div className="first-pg-title-container">
          <h1 className="welcome-title">{title}</h1>
          <h2 className="welcome-title first-pg-subtitle">{message}</h2>
        </div>
        <div className="first-pg-butt-container">
          <button
            className="button button2 first-pg-button"
            onClick={() => navigate("/login")}
            color="inherit"
          >
            Administrateur
          </button>
          <button
            className="button button2 first-pg-button"
            onClick={() => navigate("/formation")}
            color="inherit"
          >
            Formation
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;

import React, { useState, useContext, useEffect } from "react";
import { TutorielsContext } from "../App";
import FeedBackModal from "../components/FeedbackModal";

import { useNavigate } from "react-router";

import { db } from "../Firebase/firebase-config";
import {doc, getDoc, getDocs, collection } from "firebase/firestore";

import TutoForm from "../components/TutoForm";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

const TutosProviderForm = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const pageAttributesRef = doc(db, "pages", "pageFinTutoriel");
  const [tutos, setTutos] = useState([]);
  const tutosRef = collection(db, "tutoriels");
  const { numQuestion, currQuestion, setCurrQuestion } =
    useContext(TutorielsContext);
  const [numCorrectResponses, setNumCorrectResponses] = useState(0);
  const [numIncorrectResponses, setNumIncorrectResponses] = useState(0);
  const [numCorrResponsesTot, setNumCorrResponsesTot] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const getPageAttributes = () => {
      getDocs(tutosRef).then((docs) => {
        let tutosTemp = [];
        docs.forEach((doc) => {
          tutosTemp.push({ id: doc.id, data: doc.data() });
        });
        const shuffledTutos = tutosTemp
          .sort(() => Math.random() - 0.5)
          .slice(0, numQuestion);
        setTutos(shuffledTutos);
      });

      // recuperer les informations de la page de fin de tutoriel
      getDoc(pageAttributesRef).then((doc) => {
        setTitle(doc.data().title);
        setMessage(doc.data().message);
      });
    };
    getPageAttributes();
  }, []);

  const handleRestart = (e) => {
    e.preventDefault();
    setCurrQuestion(0);
    setNumCorrectResponses(0);
    setNumIncorrectResponses(0);
    setNumCorrResponsesTot(0);
    setTutos((prevState) => prevState.sort(() => Math.random() - 0.5)); //reordonner aleatoirement les tutos
    setIsModalOpen(false);
  };

  const handleQuit = (e) => {
    e.preventDefault();
    setIsModalOpen(false);
    navigate("/");
  };

  const getRounded = () => {
    const number = (numCorrectResponses / numCorrResponsesTot) * 100;

    console.log(numCorrectResponses, numCorrResponsesTot);
    return Math.round((number + Number.EPSILON) * 100) / 100;
  };

  console.log(isModalOpen);
  return (
    <div className="page-container tuto-prov-container">
      <div className="logo-container">
        <div className="logo-img-container jbthiery-logo-container">
          <img
            className="logo"
            src="./img/jbthierrylogo.png"
            alt="logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="logo-img-container mines-logo-container">
          <img
            className="logo"
            src="./img/mines-nancy.png"
            alt="logo"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
      <div className="tutos-prov-msg-container tutos-prov-msg-container-form">
        <div className="tuto-pg-header">
          <div className="tuto-pg-header-container-form">
            <div className="tuto-pg-progress-count-container">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="correctIcon count-icon"
              />
              <Typography sx={{ fontWeight: "bold" }}>
                {numCorrectResponses}
              </Typography>
            </div>
            <ProgressBar
              now={((currQuestion + 1) / numQuestion) * 100}
              label={`${currQuestion + 1} sur ${numQuestion}`}
            />
            <div className="tuto-pg-progress-count-container">
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="incorrectIcon count-icon"
              />
              <Typography sx={{ fontWeight: "bold" }}>
                {numIncorrectResponses}
              </Typography>
            </div>
          </div>
        </div>
        <div className="tuto-pg-body">
          {tutos[currQuestion] && (
            <TutoForm
              id={tutos[currQuestion].id}
              data={tutos[currQuestion].data}
              setNumCorrectResponses={setNumCorrectResponses}
              setNumIncorrectResponses={setNumIncorrectResponses}
              setIsParentModalOpen={setIsModalOpen}
              setNumCorrResponsesTot={setNumCorrResponsesTot}
            />
          )}
        </div>
        <div className="tuto-pg-footer tuto-pg-footer-form">
          <div className="tuto-pg-footer-butt-container tuto-pg-footer-butt-container-form">
            <button
              className="button button2 tuto-pg-footer-butt"
              onClick={() => navigate("/")}
            >
              Quitter
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <FeedBackModal>
          <div className="feedback-modal-title-container-form">
            <Typography variant="h3">{title}</Typography>
          </div>
          <div className="feedback-modal-resp-container-form end-feedback-resp-container">
            <List>
              <ListItem key="correct-responses-count">
                <ListItemText
                  primary={`Pourcentage de reponses correctes accumulées : ${getRounded()} %`}
                />
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="correctIcon"
                  />
                </ListItemIcon>
              </ListItem>
            </List>
          </div>
          <div className="feedback-modal-msg-container-admn feedback-modal-msg-container-form">
            <p>{message}</p>
          </div>
          <div className="modal-butt-container feedback-modal-butt-container-admn feedback-modal-butt-container-form ">
            <button
              className="button button2 modal-butt"
              onClick={handleRestart}
            >
              Recommencer
            </button>
            <button className="button button2 modal-butt" onClick={handleQuit}>
              Quitter
            </button>
          </div>
        </FeedBackModal>
      )}
    </div>
  );
};

export default TutosProviderForm;

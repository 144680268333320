import React, { useState, useContext, useEffect } from "react";

import { auth } from "../Firebase/firebase-config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { AuthContext } from "../Firebase/Auth";
import { useNavigate } from "react-router-dom";

import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState("");
  const { currentUser } = useContext(AuthContext);

  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

  useEffect(() => {
    if (currentUser) {
        navigate("/admin");
    }
  }, [currentUser]);

  const handleSubmit = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        navigate("/admin");
      })

      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          setError("Adresse email invalide");
        }
        if (error.code === "auth/wrong-password") {
          setError("Mot de passe invalide");
        }
        if (error.code === "auth/too-many-requests") {
          setError(
            "Le compte lié à cet email a été désactivé temporairement suite à plusieurs tentatives de connexion"
          );
        }
      });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="page-container">
      <div className="logo-container">
        <div className="logo-img-container jbthiery-logo-container">
          <img
            className="logo"
            src="./img/jbthierrylogo.png"
            alt="logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="logo-img-container mines-logo-container">
          <img
            className="logo"
            src="./img/mines-nancy.png"
            alt="logo"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
      <div className="login-pg-msg-container">
        <div className="login-pg-form-container">
          <div className="login-pg-title-container">
            <h1>
                Connexion
            </h1>

          </div>
          <FormControl sx={{ width: "50%" }} variant="standard">
            <InputLabel sx={{ fontSize: "1.2rem" }}>Email</InputLabel>
            <Input
              placeholder="exemple@gmail.com"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormControl>
          <FormControl sx={{ width: "50%" }} variant="standard">
            <InputLabel
              htmlFor="standard-adornment-password"
              sx={{ fontSize: "1.2rem" }}
            >
              Mot de passe
            </InputLabel>
            <Input
              id="standard-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <p className={`error ${error === "" ? 'hidden':''}`}>{error}</p>
        </div>
        
        <div className="login-pg-butt-container">
          <button
            className="button button2 login-pg-button"
            onClick={handleSubmit}
            disabled={!(emailRegex.test(email) && (password.length > 4))}
            color="inherit"
          >
            Se connecter
          </button>
          <button
            className="button button2 login-pg-button"
            onClick={() => navigate("/")}
            color="inherit"
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
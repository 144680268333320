import React from "react";
import ReactDOM from "react-dom";

const Modal = ({ onClose, isNumFilled, children }) => {
  return ReactDOM.createPortal(
    <div className="modal-container">
      <div className="modal-content">
        <div className="msg-container">{children}</div>
        <div className="modal-butt-container">
          <button
            className="button button2 modal-butt"
            onClick={() => onClose(true)}
            disabled={!isNumFilled}
          >
            Continuer
          </button>
          <button
            className="button button2 modal-butt"
            onClick={() => onClose(false)}
          >
            Annuler
          </button>
        </div>
      </div>
    </div>,

    document.getElementById("portal")
  );
};

export default Modal;

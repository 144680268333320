import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { TutorielsContext } from "../App";
import EdiText from "react-editext";

import { auth } from "../Firebase/firebase-config";
import { signOut } from "firebase/auth";

import { db } from "../Firebase/firebase-config";
import {
  doc,
  updateDoc,
  getDoc,
  collection,
  getCountFromServer,
} from "firebase/firestore";

import { AuthContext } from "../Firebase/Auth";

const StartingPageAdm = () => {
  const navigate = useNavigate();
  const { setNumQuestion } = useContext(TutorielsContext);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const pageAttributesRef = doc(db, "pages", "pageExplicationFormation");
  const tutosRef = collection(db, "tutoriels");
  const { currentUser } = useContext(AuthContext);

  const handleTitleUpdate = (newValue) => {
    if (newValue !== title) {
      updateDoc(pageAttributesRef, { title: newValue }).then(
        () => {
          setTitle(newValue);
        },
        (err) => alert(err)
      );
    }
  };

  const handleMessageUpdate = (newValue) => {
    if (newValue !== message) {
      updateDoc(pageAttributesRef, { message: newValue }).then(
        () => {
          console.log("ok");
          setMessage(newValue);
        },
        (err) => alert(err)
      );
    }
  };

  useEffect(() => {
    const getPageAttributes = () => {
      getDoc(pageAttributesRef).then((doc) => {
        setTitle(doc.data().title);
        setMessage(doc.data().message);
      });
      getCountFromServer(tutosRef).then((snapshot) =>
        setNumQuestion(snapshot.data().count)
      );
    };
    if (!currentUser) {
      navigate("/login");
    } else {
      getPageAttributes();
    }
  }, []);

  const handleNavToQuestions = (e) => {
    e.preventDefault();
    navigate("/admintutos");
  };

  return (
    <div className="page-container">
      <div className="logo-container">
        <div className="logo-img-container jbthiery-logo-container">
          <img
            className="logo"
            src="./img/jbthierrylogo.png"
            alt="logo"
            onClick={() => {
              signOut(auth).then(
                (_) => navigate("/"),
                (err) => alert(err)
              );
            }}
          />
        </div>
        <div className="logo-img-container mines-logo-container">
          <img
            className="logo"
            src="./img/mines-nancy.png"
            alt="logo"
            onClick={() => {
              signOut(auth).then(
                (_) => navigate("/"),
                (err) => alert(err)
              );
            }}
          />
        </div>
      </div>

      <div className="start-pg-msg-container">
        <div className="title-container">
          <EdiText
            showButtonsOnHover
            type="text"
            value={title}
            viewProps={{
              className: "welcome-title",
            }}
            onSave={(newTitle) => handleTitleUpdate(newTitle)}
          />
        </div>

        <div className="welcome-msg">
          <EdiText
            showButtonsOnHover
            viewContainerClassName="my-custom-view-wrapper"
            type="textarea"
            inputProps={{
              rows: 18,
            }}
            saveButtonContent="Appliquer"
            cancelButtonContent={<strong>Annuler</strong>}
            editButtonContent="Modifier"
            value={message}
            onSave={(newMsg) => handleMessageUpdate(newMsg)}
          />
        </div>
        <div className="start-pg-butt-container">
          <button
            className="button button2 start-pg-button"
            onClick={(e) => handleNavToQuestions(e)}
            color="inherit"
          >
            Questions
          </button>
          <button
            className="button button2 start-pg-button"
            onClick={() => {
              signOut(auth).then(
                (_) => navigate("/"),
                (err) => alert(err)
              );
            }}
            color="inherit"
          >
            Quitter
          </button>
        </div>
      </div>
    </div>
  );
};

export default StartingPageAdm;

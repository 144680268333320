import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { TutorielsContext } from "../App";

import Modal from "../components/Modal";

import { db } from "../Firebase/firebase-config";
import {
  doc,
  getDoc,
  collection,
  getCountFromServer,
} from "firebase/firestore";

import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

const StartingPageForm = () => {
  const navigate = useNavigate();
  const { setNumQuestion, setCanNavigate, setCurrQuestion } =
    useContext(TutorielsContext);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isNumFilled, setIsNumFilled] = useState(false);
  const pageAttributesRef = doc(db, "pages", "pageExplicationFormation");
  const tutosRef = collection(db, "tutoriels");
  const [maxNumQuestion, setMaxNumQuestion] = useState(null);

  const minNumQuestion = 1;

  const handleModalClose = (toContinue) => {
    setIsOpen(false);
    if (toContinue) {
      setCanNavigate(true);
      setCurrQuestion(0);
      navigate("/formtutos");
    }
  };

  const handleFilledChange = (e) => {
    const value = e.target.value ? parseInt(e.target.value) : undefined;
    setNumQuestion(value);
    if (value && value >= minNumQuestion && value <= maxNumQuestion) {
      setIsNumFilled(true);
    } else {
      setIsNumFilled(false);
    }
  };

  useEffect(() => {
    const getPageAttributes = () => {
      getDoc(pageAttributesRef).then((doc) => {
        setTitle(doc.data().title);
        setMessage(doc.data().message);
      });
      getCountFromServer(tutosRef).then((snapshot) =>
        setMaxNumQuestion(snapshot.data().count)
      );
    };
    getPageAttributes();
  }, []);

  return (
    <div className="page-container">
      <div className="logo-container">
        <div className="logo-img-container jbthiery-logo-container">
          <img
            className="logo"
            src="./img/jbthierrylogo.png"
            alt="logo"
            onClick={() => navigate("/")}
          />
        </div>
        <div className="logo-img-container mines-logo-container">
          <img
            className="logo"
            src="./img/mines-nancy.png"
            alt="logo"
            onClick={() => navigate("/")}
          />
        </div>
      </div>
      <div className="start-pg-msg-container">
        <div className="title-container">
          <h1 className="welcome-title">{title}</h1>
        </div>

        <div className="welcome-msg form-welcome-msg">
          <p>{message}</p>
        </div>
        <div className="start-pg-butt-container">
          <button
            className="button button2 start-pg-button"
            onClick={() => setIsOpen(true)}
            color="inherit"
          >
            Commencer
          </button>
          <button
            className="button button2 start-pg-button"
            onClick={() => navigate("/")}
            color="inherit"
          >
            Annuler
          </button>
        </div>
      </div>
      {isOpen && (
        <Modal onClose={handleModalClose} isNumFilled={isNumFilled}>
          <Typography variant="h2">
            Veuillez rentrer le nombre de questions
          </Typography>
          <TextField
            required
            id="outlined-number"
            label="Nombre"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: minNumQuestion,
                max: maxNumQuestion,
              },
            }}
            onChange={(e) => handleFilledChange(e)}
            helperText={
              maxNumQuestion === 1
                ? `Vous ne pouvez choisir qu'une seule question`
                : `Vous pouvez choisir entre ${minNumQuestion} et ${maxNumQuestion} questions`
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default StartingPageForm;

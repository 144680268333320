import React, { useState } from "react";
import TutosProviderAdm from "./pages/TutosProviderAdm";
import TutosProviderForm from "./pages/TutosProviderForm";
import Login from "./pages/Login";
import StartingPageAdm from "./pages/StartingPageAdm";
import StartingPageForm from "./pages/StartingPageForm";
import FirstPage from "./pages/FirstPage";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import NotFound from "./pages/NotFound";
import "bootstrap/dist/css/bootstrap.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AuthProvider } from "./Firebase/Auth";

export const TutorielsContext = React.createContext({
  numQuestion: null,
  setNumQuestion: (newNum) => {},
  canNavigate: false,
  setCanNavigate: (v) => {},
  currQuestion: 0,
  setCurrQuestion: (v) => {},
});

function App() {
  const [numQuestion, setNumQuestion] = useState(null);
  const [canNavigate, setCanNavigate] = useState(false);
  const [currQuestion, setCurrQuestion] = useState(null);

  return (
    <AuthProvider>
      <TutorielsContext.Provider
        value={{
          numQuestion,
          setNumQuestion,
          canNavigate,
          setCanNavigate,
          currQuestion,
          setCurrQuestion,
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>
            <Routes>
              {canNavigate && (
                <Route path="/formtutos" element={<TutosProviderForm />} />
              )}

              <Route path="/" element={<FirstPage />} />

              <Route path="/admintutos" element={<TutosProviderAdm />} />

              <Route path="/admin" element={<StartingPageAdm />} />

              <Route path="/formation" element={<StartingPageForm />} />

              <Route path="/login" element={<Login />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </DndProvider>
      </TutorielsContext.Provider>
    </AuthProvider>
  );
}

export default App;

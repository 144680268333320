import React, { useState, useContext, useEffect } from "react";
import { TutorielsContext } from "../App";
import { AuthContext } from "../Firebase/Auth";

import { useNavigate } from "react-router";

import { auth } from "../Firebase/firebase-config";
import { signOut } from "firebase/auth";

import { db } from "../Firebase/firebase-config";
import {
  doc,
  getDoc,
  deleteDoc,
  getDocs,
  collection,
  addDoc,
} from "firebase/firestore";

import TutoAdmin from "../components/TutoAdmin";
import ProgressBar from "react-bootstrap/ProgressBar";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const TutosProviderAdm = () => {
  const navigate = useNavigate();
  const [tutos, setTutos] = useState([]);
  const tutosRef = collection(db, "tutoriels");
  const { numQuestion, currQuestion, setNumQuestion, setCurrQuestion } =
    useContext(TutorielsContext);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const getPageAttributes = () => {
      getDocs(tutosRef).then((docs) => {
        let tutosTemp = [];
        docs.forEach((doc) => {
          tutosTemp.push({ id: doc.id, data: doc.data() });
        });
        setTutos(tutosTemp);
        setCurrQuestion(0);
      });
    };
    currentUser ? getPageAttributes() : navigate("/login");
  }, []);

  const addNewTuto = (e) => {
    e.preventDefault();
    addDoc(tutosRef, {
      description: "Nouvelle description...",
      videoURL: "https://www.youtube.com/embed/NpEaa2P7qZI",
      question: "Nouvelle question ?",
      feedback: "Nouveau feedback...",
      responses: [
        { content: "Nouvelle reponse...", correct: true },
        { content: "Nouvelle reponse...", correct: false },
      ],
    }).then((newDocSnapshot) => {
      const newDocRef = doc(db, "tutoriels", newDocSnapshot.id);
      getDoc(newDocRef).then((newDoc) => {
        setCurrQuestion(numQuestion);
        setTutos((prevState) => [
          ...prevState,
          { id: newDoc.id, data: newDoc.data() },
        ]);
        setNumQuestion((prevState) => prevState + 1);
      });
    });
  };

  const updateTuto = (tutoId) => {
    return new Promise((resolve, reject) => {
      const docRef = doc(db, "tutoriels", tutoId);
      getDoc(docRef).then(
        (docSnapshot) => {
          const updatedTutos = tutos.map((tuto) => {
            if (tuto.id === tutoId) {
              return { ...tuto, data: docSnapshot.data() };
            } else {
              return tuto;
            }
          });
          resolve(updatedTutos);
        },
        (err) => reject(err)
      );
    });
  };

  const handleNavigateTo = (event, value) => {
    event.preventDefault();
    const tutoId = tutos[value - 1].id;
    updateTuto(tutoId).then(
      (updatedTutos) => {
        setTutos(updatedTutos);
        setCurrQuestion(value - 1);
      },
      (err) => alert(err)
    );
  };

  const handleDeleteCurrTuto = (event) => {
    event.preventDefault();
    const docToDeleteID = tutos[currQuestion].id;
    const docToDeleteRef = doc(db, "tutoriels", docToDeleteID);
    deleteDoc(docToDeleteRef).then((_) => {
      setTutos((prevState) =>
        prevState.filter((docTemp) => docTemp.id !== docToDeleteID)
      );

      setNumQuestion((prevState) => prevState - 1);
      if (currQuestion > 0) setCurrQuestion((prevState) => prevState - 1);
    });
  };
  return (
    <div className="page-container tuto-prov-container">
      <div className="logo-container">
        <div className="logo-img-container jbthiery-logo-container">
          <img
            className="logo"
            src="./img/jbthierrylogo.png"
            alt="logo"
            onClick={() => {
              signOut(auth).then(
                (_) => navigate("/"),
                (err) => alert(err)
              );
            }}
          />
        </div>
        <div className="logo-img-container mines-logo-container">
          <img
            className="logo"
            src="./img/mines-nancy.png"
            alt="logo"
            onClick={() => {
              signOut(auth).then(
                (_) => navigate("/"),
                (err) => alert(err)
              );
            }}
          />
        </div>
      </div>
      <div className="tutos-prov-msg-container">
        <div className="tuto-pg-header">
          <ProgressBar
            now={((currQuestion + 1) / numQuestion) * 100}
            label={`${currQuestion + 1} sur ${numQuestion}`}
          />
        </div>
        <div className="tuto-pg-body">
          {tutos[currQuestion] && (
            <TutoAdmin
              key={currQuestion}
              id={tutos[currQuestion].id}
              data={tutos[currQuestion].data}
            />
          )}
        </div>
        <div className="tuto-pg-footer">
          <div className="tuto-pg-footer-pagination-container">
            <Pagination
              count={numQuestion}
              page={currQuestion + 1}
              onChange={handleNavigateTo}
              variant="outlined"
              shape="rounded"
              size="large"
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
          </div>
          <div className="tuto-pg-footer-butt-container">
            <button
              className="button button2 tuto-pg-footer-butt"
              onClick={(e) => addNewTuto(e)}
            >
              Nouveau
            </button>
            <button
              className="button button2 tuto-pg-footer-butt"
              onClick={handleDeleteCurrTuto}
              disabled={numQuestion <= 1}
            >
              Supprimer
            </button>
            <button
              className="button button2 tuto-pg-footer-butt"
              onClick={() => {
                signOut(auth).then(
                  (_) => navigate("/"),
                  (err) => alert(err)
                );
              }}
            >
              Quitter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutosProviderAdm;

import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";

import { TutorielsContext } from "../App";

import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import ReactPlayer from "react-player";

import FeedBackModal from "./FeedbackModal";

const TutoForm = (props) => {
  const navigate = useNavigate();
  const [responses, setResponses] = useState(
    props.data.responses.map((resp) => ({ ...resp, checked: false }))
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [helperText, setHelperText] = useState(
    "Cochez la(les) bonne(s) reponse(s)"
  );
  const { numQuestion, currQuestion, setCurrQuestion } =
    useContext(TutorielsContext);

  const handleChoiceChange = (e, respId) => {
    // modifie les choix de l'utilisateur
    e.preventDefault();

    const updatedResponses = responses.map((respTemp, respTempId) => {
      return respId === respTempId
        ? { ...respTemp, checked: !respTemp.checked }
        : respTemp;
    });

    setResponses(updatedResponses);
  };

  const handleSubmitResponses = (e) => {
    e.preventDefault();

    const correctResponses = responses.reduce((acc, choice) => {
      return choice.checked && choice.correct ? acc + 1 : acc;
    }, 0);
    const correctResponsesTot = responses.reduce((acc, choice) => {
      return choice.correct ? acc + 1 : acc;
    }, 0);
    const incorrectResponses = responses.reduce((acc, choice) => {
      return choice.checked && !choice.correct ? acc + 1 : acc;
    }, 0);
    props.setNumCorrectResponses((prevState) => prevState + correctResponses);
    props.setNumCorrResponsesTot(
      (prevState) => prevState + correctResponsesTot
    );
    props.setNumIncorrectResponses(
      (prevState) => prevState + incorrectResponses
    );

    setIsModalOpen(true);
  };

  const checkCanValidate = () => {
    const numResponsesChecked = responses.reduce((acc, resp) => {
      return resp.checked === true ? acc + 1 : acc;
    }, 0);
    return numResponsesChecked >= 1;
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    currQuestion === numQuestion - 1
      ? props.setIsParentModalOpen(true)
      : setCurrQuestion((prevState) => prevState + 1);
    setResponses(
      props.data.responses.map((resp) => ({ ...resp, checked: false }))
    );
    setIsModalOpen(false);
  };

  console.log("child", isModalOpen);
  console.log(
    "correct",
    responses.reduce((acc, choice) => {
      return choice.correct ? acc + 1 : acc;
    }, 0)
  );
  return (
    <div className="tuto-container">
      <div className="left-vid-block">
        <div className="vid-container vid-container-form">
          <ReactPlayer url={props.data.videoURL} controls={true} />
        </div>
      </div>
      <div className="tuto-pg-right-text-block">
        <div className="tuto-pg-explication-block tuto-pg-explication-block-form">
          <p>{props.data.description}</p>
        </div>
        <div className="tuto-pg-questions-block tuto-pg-questions-block-form">
          <div className="tuto-pg-question-container">
            <p className="tuto-pg-question">{props.data.question}</p>
          </div>
          <form className="tuto-pg-responses-form">
            <FormControl
              sx={{ width: "100%", height: "100%" }}
              variant="standard"
            >
              {responses.map((resp, respId) => (
                <div
                  key={respId}
                  className="tuto-pg-response-wrapper tuto-pg-response-wrapper-form "
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={resp.checked}
                        onClick={(e) => handleChoiceChange(e, respId)}
                      />
                    }
                    label={resp.content}
                  />
                </div>
              ))}
              <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
          </form>
          <div className="tuto-pg-submit-button-container">
            <button
              className="button button2 tuto-pg-submit-butt"
              onClick={handleSubmitResponses}
              disabled={!checkCanValidate()}
            >
              Valider
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <FeedBackModal>
          <div className="feedback-modal-title-container-form">
            <Typography variant="h3">Commentaires</Typography>
          </div>
          <div className="feedback-modal-resp-container-form">
            <List>
              {responses
                .filter((resp) => resp.checked === true)
                .map((resp) => (
                  <ListItem key={resp}>
                    <ListItemText primary={resp.content} />
                    <ListItemIcon>
                      {resp.correct ? (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="correctIcon"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="incorrectIcon"
                        />
                      )}
                    </ListItemIcon>
                  </ListItem>
                ))}
            </List>
          </div>
          <div className="feedback-modal-msg-container-admn feedback-modal-msg-container-form">
            <p>{props.data.feedback}</p>
          </div>
          <div className="modal-butt-container feedback-modal-butt-container-admn feedback-modal-butt-container-form ">
            <button
              className="button button2 modal-butt"
              onClick={handleModalClose}
            >
              Continuer
            </button>
            <button
              className="button button2 modal-butt"
              onClick={() => navigate("/")}
            >
              Quitter
            </button>
          </div>
        </FeedBackModal>
      )}
    </div>
  );
};

export default TutoForm;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDvEdyUigHH_9AVWwobcEKFLptCvXtUnxQ",
  authDomain: "jbthierry-f156d.firebaseapp.com",
  projectId: "jbthierry-f156d",
  storageBucket: "jbthierry-f156d.appspot.com",
  messagingSenderId: "1017254995022",
  appId: "1:1017254995022:web:46cd417cd7bb0d9769cf1f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

export{ auth, storage, db };
 
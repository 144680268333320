import React from "react";
import ReactDOM from "react-dom";

const FeedBackModal = ({ children }) => {
  return ReactDOM.createPortal(
    <div className="modal-container feedback-modal-container">
      <div className="modal-content feedback-modal-content">{children}</div>
    </div>,

    document.getElementById("portal")
  );
};

export default FeedBackModal;
